import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import moment from 'moment';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";

import {
  getCompanies as onGetCompanies,
} from "store/companies/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

const ContactsList = () => {

  document.title = "Utilisateurs";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: (contact && contact.firstname) || "",
      lastname: (contact && contact.lastname) || "",
      fonction: (contact && contact.fonction) || "",
      email: (contact && contact.email) || "",
      company: contact ? contact?.company?.map(c => c.value) : [],
      password: '',
      role: (contact && contact.role) || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Entrez votre prénom"),
      lastname: Yup.string().required("Entrez votre nom"),
      fonction: Yup.string().required("Entrez le poste du salarié"),
      email: Yup.string().email("Adresse email invalide").required("Entrez votre email"),
      company: Yup.array().of(Yup.string()).required("Compagnie obligatoire"),
      password: isEdit ? Yup.string() : Yup.string().required("Mot de passe obligatoire"),
      role: Yup.string().required("Role obligatoire")
    }),

    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          _id: contact._id,
          firstname: values.firstname,
          lastname: values.lastname,
          fonction: values.fonction,
          email: values.email,
          company: values.company,
          role: values.role,
          ...(values.password ? {password: values.password} : '')
        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          firstname: values["firstname"],
          lastname: values["lastname"],
          fonction: values["fonction"],
          email: values["email"],
          company: values["company"],
          password: values["password"],
          role: values["role"],
        };
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const ContactsProperties = createSelector(
    (state) => state.contacts,
    (Contacts) => ({
      users: Contacts.users,
      loading: Contacts.loading
    })
  );

  const CompaniesProperties = createSelector(
    (state) => state.Companies,
    (Companies) => {
      return ({
        companies: Companies.companies,
        loading: Companies.loading
      })
    }
  );

  const {
    users, loading
  } = useSelector(ContactsProperties);

  const {
    companies
  } = useSelector(CompaniesProperties);

  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    if (!users || users.length === 0) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
    if (!companies || companies.length === 0) {
      dispatch(onGetCompanies());
      setIsEdit(false);
    }
  }, [dispatch, users, companies]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    const formattedCompany = user.company ? user.company.map(c => ({ value: c._id, label: c.name })) : [];

    setContact({
      _id: user._id,
      firstname: user.firstname,
      lastname: user.lastname,
      fonction: user.fonction,
      email: user.email,
      company: formattedCompany,
      role: user.role,
    });
    setIsEdit(true);
    toggle();
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (users) => {
    setContact(users._id);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact) {
      dispatch(onDeleteUser(contact));
    }
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "img",
        cell: (cell) => (
          <>
            {!cell.getValue() ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cell.row.original.firstname.charAt(0).toUpperCase()}
                  {cell.row.original.lastname.charAt(0).toUpperCase()}
                </span>
              </div>
            ) : (
              <div>
                <img className="rounded-circle avatar-xs" src={cell.getValue()} alt="" />
              </div>
            )}
          </>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Prénom',
        accessorKey: 'firstname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Nom',
        accessorKey: 'lastname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Fonction',
        accessorKey: 'fonction',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Société',
        accessorKey: 'company',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return <span>{(cell?.getValue()||[]).map(c => c.name).join(', ')}</span>
        }
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return (
            <div>
              {cell.getValue() ===  'active' ?
                <Link to="#1" className="badge badge-soft-primary font-size-11 m-1" >{cell.getValue()}</Link> :
                <Link to="#1" className="badge badge-soft-warning font-size-11 m-1" >{cell.getValue()}</Link>
              }
            </div>
          );
        },
      },
      {
        header: 'Date de création',
        accessorKey: 'creationDate',
        enableColumnFilter: false,
        enableSorting: true,
        align: 'right',
        cell: (cell) => {
          return (
            <div>
              {moment(cell).format('DD-MM-YYYY')}
            </div>
          )
        }
      },
      {
        header: 'Role',
        accessorKey: 'role',
        enableColumnFilter: false,
        enableSorting: true,
        align: 'left',
        cell: (cell) => {
          const elt = [
            {role: 'super-admin', name: 'Admin', badge: 'warning'},
            {role: 'admin', name: 'Adm./comm.', badge: 'success'},
            {role: 'commercial', name: 'Commercial',  badge: 'info'},
          ].find((elt) => elt.role === cell.getValue())
          return (
            <div className={`badge badge-soft-${elt?.badge} font-size-11 m-1`}>
              {elt?.name}
            </div>
          )
        }
      },
      {
        header: 'Action',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original; onClickDelete(userData);
                }}>
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const companyOptions = companies.map(c => ({ value: c._id, label: c.name }));
  const handleCompanyChange = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    validation.setFieldValue('company', selectedValues);
  };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Comptes" breadcrumbItem="Liste des comptes" />
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={users || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="Search..."
                        isCustomPageSize={true}
                        isAddButton={true}
                        handleUserClick={handleUserClicks}
                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                        buttonName="Nouveau Contact"
                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }

            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Modifier compte" : "Ajouter Compte"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Prénom</Label>
                        <Input
                          name="firstname"
                          type="text"
                          placeholder="Votre prénom"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                          invalid={
                            validation.touched.firstname &&
                              validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                          validation.errors.firstname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstname}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Nom</Label>
                        <Input
                          name="lastname"
                          type="text"
                          placeholder="Votre nom"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          invalid={
                            validation.touched.lastname &&
                            validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                        validation.errors.lastname ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Poste du salarié</Label>
                        <Input
                          name="fonction"
                          type="text"
                          placeholder="Fonction"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.fonction || ""}
                          invalid={
                            validation.touched.fonction &&
                            validation.errors.fonction
                              ? true
                              : false
                          }
                        />
                        {validation.touched.fonction &&
                        validation.errors.fonction ? (
                          <FormFeedback type="invalid">
                            {validation.errors.fonction}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="text"
                          placeholder="Votre email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email &&
                            validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email &&
                        validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Mot de passe</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Mot de passe"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Role</Label>
                        <select
                          name="role"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.role || ""}
                          className={`form-control ${
                            validation.touched.role && validation.errors.role ? "is-invalid" : ""
                          }`}
                        >
                          <option>Sélectionnez un role</option>
                          {[{id: 'super-admin', name: 'Admin (seul)'},
                            {id: 'admin', name: 'Admin (commercial)'},
                            {id: 'commercial', name: 'Commercial'}].map(c => (
                            <option key={`role-${c.id}`} value={c.id}>{c.name}</option>
                          ))}
                        </select>
                        {validation.touched.company && validation.errors.company && (
                          <FormFeedback type="invalid">
                            {validation.errors.company}
                          </FormFeedback>
                        )}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Entreprise</Label>
                        <Select
                          name="company"
                          isMulti
                          options={companyOptions}
                          classNamePrefix="select"
                          onChange={handleCompanyChange}
                          value={companyOptions.filter(option => validation?.values?.company?.includes(option.value))}
                          onBlur={() => validation.setFieldTouched('company', true)}
                        />
                        {validation.touched.company && validation.errors.company && (
                          <FormFeedback type="invalid">
                            {validation.errors.company}
                          </FormFeedback>
                        )}
                      </div>

                    </Col>
                  </Row>
                  <Row  style={{marginTop: '50px'}}>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Enregistrer
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
