import React from "react"
import { useDispatch } from "react-redux"
import { Card, CardBody, CardImg, CardSubtitle, CardTitle, Col, Container, Row } from "reactstrap"
import "App.css"
import { getEvents as onGetEvents } from "../../../store/calendar/actions"


const UserProfileSelector = ({ users,  userProfile, setUserProfile, company, dateStart, dateEnd }) => {
  const dispatch = useDispatch()

  return (
    <Container>
      <Row>
        <Col md="12" sm="12" xs={12}>
          <Row>
            <h4 className={"p-3 text-center"}>
              Prendre rendez-vous avec <br />
              un interlocuteur Fideliance
            </h4>
          </Row>
          <Row>
            {users
              .filter(user => user.role !== 'super-admin')
              .filter(user => user.company.find(c => {
                return c._id === company
              }))
              .map(user => {
              const style = userProfile?._id.toString() === user._id.toString() ? {
                boxShadow: "0 0 10px rgba(0,0,0,0.5)",
                cursor: "pointer"
              } : {}
              return (
                <Col sm="4" md="6" xs="6" key={user._id} className="mb-4" >
                  <Card
                    className={"text-center"}
                    style={style}
                    onClick={() => {
                      const newUser = users.find(u => u._id.toString() === user._id.toString());
                      setUserProfile(newUser)
                      dispatch(onGetEvents({
                        user: newUser._id,
                        dateStart,
                        dateEnd,
                        company
                      }))
                    }}>
                    <CardImg top style={{ width: "35px", height: "35px", borderRadius: "50%" }}
                             src={user?.image || `${process.env.PUBLIC_URL}/image/prodefault.webp`}
                             alt="Photo de profil"
                             className="mx-auto mt-3" />
                    <CardBody style={{minHeight: '120px'}}>
                      <CardTitle tag="h5">{`${user.firstname} ${user.lastname}`}</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">{user?.fonction || ""}</CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default UserProfileSelector
