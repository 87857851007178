import React, { createContext, useState, useEffect } from 'react';
import {me} from '../helpers/user' ;

export const UserProfileContext = createContext(null);

export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading,  setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setIsLoading(true);
        const response = await me();
        console.log("me response", response);
        setUserProfile(response);
      } catch (error) {
        console.error("Erreur lors du chargement du profil utilisateur", error);
      } finally {
        setIsLoading(false); // Fin du chargement
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <UserProfileContext.Provider value={{ userProfile, isLoading }}>
      {children}
    </UserProfileContext.Provider>
  );
};