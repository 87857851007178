import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Badge } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getCompanies as onGetCompanies,
  addNewCompany as onAddNewCompany,
  updateCompany as onUpdateCompany,
  deleteCompany as onDeleteCompany,
} from "store/companies/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import Companies from "../../store/companies/reducer"

const List = () => {

  //meta title
  document.title = "Liste des sociétés";

  const dispatch = useDispatch();
  const [company, setCompany] = useState();
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (company && company.name) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      address: Yup.string().required("Please Enter Your address"),
      city: Yup.string().required("Please Enter Your city"),
      zipcode: Yup.string().required("Please Enter Your zipcode"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateCompany = {
          _id: company._id.toString(),
          name: values.name,
          address: values.address,
          city:  values.city,
          zipcode: values.zipcode
        };
        // update user
        dispatch(onUpdateCompany(updateCompany));
        dispatch(onGetCompanies());
        setIsEdit(false);
        validation.resetForm();

      } else {
        const newCompany = {
          name: values["name"],
          address: values["address"],
          city:  values["city"],
          zipcode: values["zipcode"]
        };

        dispatch(onAddNewCompany(newCompany));
        validation.resetForm();
      }
      toggle();
    },
  });

  const CompaniesProperties = createSelector(
    (state) => state.Companies,
    (Companies) => {
      return ({
        companies: Companies.companies,
        loading: Companies.loading
      })
    }
  );

  const {
    companies, loading
  } = useSelector(CompaniesProperties);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies());
      setIsEdit(false);
    }
  }, [dispatch, companies]);

  useEffect(() => {
    setCompany(companies);
    setIsEdit(false);
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(companies) && !!isEdit) {
      setCompany(companies);
      setIsEdit(false);
    }
  }, [companies]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleCompanyClick = arg => {
    const company = arg;

    setCompany({
      _id: company._id,
      name: company.name,
      address: company.address,
      city: company.city,
      zipcode: company.zipcode,
    });
    setIsEdit(true);

    toggle();
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (companies) => {
    setCompany(companies);
    setDeleteModal(true);
  };

  const handleDeleteCompany = () => {
    if (company && company._id) {
      dispatch(onDeleteCompany(company));
      dispatch(onGetCompanies());
    }
    setDeleteModal(false);
  };

  const handleCompanyClicks = () => {
    setCompany("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "_id",
        cell: (cell) => (
          <>
            {cell.getValue()?.substring(0, 15) + '...'}
          </>
        ),
        enableColumnFilter: false,
        enableSorting: true,
        width: 50,
        style: {
          maxWidth: '50px',
          minWidth: '50px',
        }
      },
      {
        header: 'Nom',
        accessorKey: 'name',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return (
            <>
              <h5 className='font-size-14 mb-1'>
                <Link to='#' className='text-dark'>{cell.getValue()}</Link>
              </h5>
              <p className="text-muted mb-0">{cell.row.original.designation}</p>
            </>
          )
        }
      },
      {
        header: 'Ville',
        accessorKey: 'address',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Code postale',
        accessorKey: 'address',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const status = getValue();
          return status === 'active' ?
            (<Badge color="success">Active</Badge>) :
            (<Badge color="danger">Inactive</Badge>);
        }
      },
      {
        header: 'Date de création',
        accessorKey: 'creationDate',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return (
            <div>{cell.getValue()}</div>
          );
        },
      },
      {
        header: 'Action',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3 justify-content-end">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleCompanyClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original; onClickDelete(userData);
                }}>
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCompany}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Société" breadcrumbItem="Liste des sociétés" />
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={companies.filter(c => c.status === 'active') || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="recherche..."
                        isCustomPageSize={true}
                        isAddButton={true}
                        handleUserClick={handleCompanyClicks}
                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                        buttonName="Ajouter"
                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }

            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Modifier société" : "Ajouter société"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Nom de la société</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Nom de la société"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name &&
                              validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name &&
                          validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Adresse</Label>
                        <Input
                          name="address"
                          type="textarea"
                          placeholder="Adresse"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                          invalid={
                            validation.touched.address &&
                            validation.errors.address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.address &&
                        validation.errors.address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Ville</Label>
                        <Input
                          name="city"
                          type="text"
                          placeholder="ville"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.city || ""}
                          invalid={
                            validation.touched.city &&
                            validation.errors.city
                              ? true
                              : false
                          }
                        />
                        {validation.touched.city &&
                        validation.errors.city ? (
                          <FormFeedback type="invalid">
                            {validation.errors.city}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Code postal</Label>
                        <Input
                          name="zipcode"
                          type="text"
                          placeholder="Code postal"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.zipcode || ""}
                          invalid={
                            validation.touched.zipcode &&
                            validation.errors.zipcode
                              ? true
                              : false
                          }
                        />
                        {validation.touched.zipcode &&
                        validation.errors.zipcode ? (
                          <FormFeedback type="invalid">
                            {validation.errors.zipcode}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Enregistrer
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(List);
