import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";
import { ADD_NEW_USER, UPDATE_USER } from "./url_helper"

export const me = () => {
  return  get(`${url.GET_USERS}/me`)
};

export const getUsers = () => {
  return  get(url.GET_USERS)
};

export const getUsersPublic = () => {
  return  get(`${url.GET_USERS}/public/list`)
}

export const getUserProfile = (id) => {
  return  get(`${url.GET_USERS}/${id}`)
};

export const addNewUser = user => {
  return  post(url.ADD_NEW_USER, user)
};

export const updateUser = user => {
  return  put(`${url.ADD_NEW_USER }/${user._id}`, user)
};

export const deleteUser = id => {
  return  del(`${url.ADD_NEW_USER }/${id}`)
};