import React, { useEffect, useState,useContext } from "react";
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { UserProfileContext } from '../../context/userProfileContext';

import {  Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import allLocales from '@fullcalendar/core/locales-all';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import Breadcrumbs from "../../components/Common/Breadcrumb";


import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
} from "../../store/actions";

import DeleteModal from "./DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

const Calender = props => {
  const { userProfile } = useContext(UserProfileContext);
  const navigate = useNavigate();

  if (!userProfile) {
    return <div>Chargement du profil utilisateur...</div>;
  }

  const handleWeekChange = (info) => {
    if (selectedCompany) {
      setDateStart(moment(info.start).format('YYYY-MM-DD'));
      setDateEnd(moment(info.end).format('YYYY-MM-DD'));
      dispatch(onGetEvents({
        user: userProfile._id,
        dateStart: moment(info.start).format('YYYY-MM-DD'),
        dateEnd: moment(info.end).format('YYYY-MM-DD'),
        company: selectedCompany._id
      }));
    }
  }

  //meta title
  document.title = "Calendrier";
  const dispatch = useDispatch();
  const [event, setEvent] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [dateStart, setDateStart ] = useState(moment().startOf('isoWeek').format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd ] = useState(moment().endOf('isoWeek').format('YYYY-MM-DD'));

  // category validation
  const categoryValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || '',
      category: (event && event.category) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Enter Your Billing Name"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateEvent = {
          _id: event._id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        };
        // update event
        dispatch(onUpdateEvent(updateEvent));
        categoryValidation.resetForm();
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          company: selectedCompany._id,
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values['category']
            ? values['category'] + " text-white"
            : "bg-primary text-white"
          ,
        };
        dispatch(onAddNewEvent(newEvent));
        categoryValidation.resetForm()
      }
      toggle();
    },
  });

  const CalendarProperties = createSelector(
    (state) => state.calendar,
    (Calendar) => ({
      events: Calendar.events,
      categories: Calendar.categories,
    })
  );

  const { events } = useSelector(CalendarProperties);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [modalCategory, setModalCategory] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(onGetEvents({
        user: userProfile._id,
        dateStart,
        dateEnd,
        company: selectedCompany._id
      }));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (!modalCategory && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({});
        setIsEdit(false);
      }, 500);
    }
  }, [modalCategory, event, isEdit]);

  useEffect(() => {
      if (userProfile) {
        if (userProfile.company.length === 0) {
          toast.error("Vous devez assigner aumoins une agence", { autoClose: 2000 });
          setTimeout(() => navigate('/contacts-list', { replace: true }), 2500);
        } else {
          setSelectedCompany(userProfile.company[0]);
        }

      }
  }, [dispatch]);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modalCategory) {
      setModalCategory(false);
      setEvent(null);
      setIsEdit(false);
    } else {
      setModalCategory(true);
    }
  };

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };
    setSelectedDay(modifiedData);
    toggle();
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event;
    setEvent({
      id: event.id,
      name: event.extendedProps.name,
      email: event.extendedProps.email,
      phone: event.extendedProps.phone,
      message: event.extendedProps.message,
      subject: event.extendedProps.subject,
      title: event.title,
      start: event.start,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0],
    });
    setDeleteId(event.id)
    setIsEdit(true);
    setModalCategory(true)
    toggle();
  };

  /**
    * On delete event
  */
  const handleDeleteEvent = () => {
    if (deleteId) {
      dispatch(onDeleteEvent(deleteId));
    }
    setDeleteModal(false);
  };

  /**
   * On category darg event
   */
  const onDrag = event => {
    event.preventDefault();
  };

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const date = event['date'];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);

    const draggedEl = event.draggedEl;
    const draggedElclass = draggedEl.className;
    if (draggedEl.classList.contains('external-event') && draggedElclass.indexOf("fc-event-draggable") == -1) {
      const modifiedData = {
        id: Math.floor(Math.random() * 100),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      };
      dispatch(onAddNewEvent(modifiedData));
    }
  };

  //set the local language
  const enLocal = {
    "code": "en-nz",
    "week": {
      "dow": 1,
      "doy": 4
    },
    "buttonHints": {
      "prev": "Previous $0",
      "next": "Next $0",
      "today": "This $0"
    },
    "viewHint": "$0 view",
    "navLinkHint": "Go to $0"
  };
  const [isLocal, setIsLocal] = useState(enLocal);
  const handleChangeLocals = (value) => {
    setIsLocal(value);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Planning" breadcrumbItem="Gestion du planning" />
          <Row>
            <Col xl={3}>
              <Card>
                <CardBody>
                  <div id="external-events" className="mt-2">
                    <br />
                    <p className="text-muted">
                      Séléctionnez l'agence sur laquelle vous souhaitez modifier le planning
                    </p>

                    <div className="mail-list mt-1">
                      {
                        (userProfile?.company || []).map((item, index) => (
                          <Link onClick={() => setSelectedCompany(item)} to="#" key={index} style={selectedCompany?._id.toString() === item._id.toString() ? {backgroundColor: '#EEE',borderRadius: '5px', padding: '5px'} : {padding: '5px'}} >
                            <span className={'mdi mdi-arrow-right-drop-circle text-info float-end'}></span>
                            {item.name}
                          </Link>
                        ))
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xs={9}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      {/* fullcalendar control */}
                      <FullCalendar
                        eventContent={renderEventContent}
                        datesSet={handleWeekChange}
                        firstDay={1}
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          listPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        timeZone="local"
                        initialView="timeGridWeek"
                        slotMinTime="09:00:00"
                        slotMaxTime="18:00:00"
                        handleWindowResize={true}
                        slotDuration={'00:30:00'}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: false
                        }}
                        locale={'fr-FR'}
                        events={events.map(e => {
                          return {
                            id: e._id,
                            title: !e.booked ? 'Disponible' : e.name,
                            name: e.name,
                            email: e.email,
                            phone: e.phone,
                            subject: e.subject,
                            message: e.message,
                            start:moment.utc(e.startDate).local().format('YYYY-MM-DD HH:MM'),
                            end: moment.utc(e.startDate).local().format('YYYY-MM-DD HH:MM'),
                            className: !e.booked ? "bg-info text-white" : "bg-warning text-white",
                          }
                        })}
                        allDaySlot={false}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                      />
                    </CardBody>
                  </Card>

                  <Modal
                    isOpen={modalCategory}
                    className={props.className}
                    centered
                  >
                    <ModalHeader toggle={toggle} tag="h5">
                      {!!isEdit ? "Supprimer la disponibilité" : `Vous rendre disponible sur ce creneau horaire ${moment(selectedDay.dateStr).format('DD/MM/YYYY HH:00')}` }
                    </ModalHeader>
                    <ModalBody className="p-4 text-center">
                      {!!isEdit && event?.name ? <div className={'bloc-rdv text-left'}>
                        <h5>Votre rendez vous du {moment(event.startDate).format('DD/MM [à] HH:mm')}</h5>
                        <ul className={'text-left'}>
                          <li><strong>Nom:</strong> {event.name}</li>
                          <li><strong>Email:</strong> {event.email}</li>
                          <li><strong>Téléphone:</strong> {event.phone}</li>
                          <li><strong>Objet:</strong> {event.subject}</li>
                          <li><strong>Message:</strong> {event.message}</li>
                        </ul>
                      </div> : ''}
                      <button onClick={() => toggle()} className="btn btn-danger me-2 btn-lg">Non</button>
                      <button onClick={(e) => {
                        if (!isEdit) {
                          dispatch(onAddNewEvent({
                            startDate: moment(selectedDay.dateStr).format('DD/MM/YYYY HH:00'),
                            endDate: moment(selectedDay.dateStr).format('DD/MM/YYYY HH:00'),
                            user: userProfile._id,
                            company: selectedCompany._id
                          }))
                        } else {
                          dispatch(onDeleteEvent(deleteId))
                        }
                        toggle();
                        setTimeout(()  => dispatch(onGetEvents()), 500);
                      }} className="btn btn-success btn-lg">Oui</button>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
};

export default Calender;
