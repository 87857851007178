import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

export const getCompanies = () => {
  return  get(url.GET_COMPANIES)
};

export const addCompany = company => {
  return  post(url.ADD_COMPANY, company)
};

export const updateCompany = company => {
  return  put(`${url.UPDATE_COMPANY}/${company._id.toString()}`, company)
};

export const deleteCompany = company => {
  return  put(`${url.UPDATE_COMPANY}/${company._id.toString()}`, {status: 'inactive'})
};